@tailwind base;

ul {
  @apply list-disc;
  @apply ml-5;
}
ol {
  @apply list-decimal;
  @apply ml-5;
}
h1 {
  @apply text-black;
  @apply text-6xl;
}
td {
  @apply border;
  @apply px-4;
  @apply py-2;
}

th {
  @apply border;
  @apply px-4;
  @apply py-2;
}

@tailwind components;

@tailwind utilities;
/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
